<template>
  <v-layout style="margin: 0px; padding: 0px; width: 100%;">
    <v-row>
      <v-col col="12" md="5">
        <v-row wrap class="mt-10">
          <v-col cols="8" offset="2">
            <v-row style="margin-top:30px;">
              <v-col cols="12" align="center">
                <router-link to="/">
                  <v-img
                    class="centerImg2"
                    :src="'img/logo.png'"
                  />
                </router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" align="center" justify="center">
                <span class="titleStyle">Logistics Company Sign Up</span><br />
                <router-link
                  :to="'/logistics-login'"
                  style="text-decoration: none; color: black;"
                >
                  <v-icon>mdi-account-lock-outline</v-icon>
                  <span style="color:#7CBF46">Have an account already?</span>
                </router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-progress-linear
                color="#7CBF46"
                v-show="loader"
                :indeterminate="true"
              ></v-progress-linear>
              <v-col cols="12">
                <v-row>
                  <v-text-field
                    v-model="regData.name"
                    label="Company Name"
                    append-icon="mdi-warehouse"
                    outlined
                    dense
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    v-model="regData.contactPerson"
                    label="Contact Person"
                    append-icon="mdi-account-settings"
                    outlined
                    dense
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    v-model="regData.email"
                    label="Email"
                    append-icon="mdi-email"
                    outlined
                    dense
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    name="password"
                    label="Password"
                    outlined
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show3 ? 'text' : 'password'"
                    id="password"
                    dense
                    v-model="regData.password"
                    @click:append="show3 = !show3"
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-row class="d-flex">
                        <v-text-field
                          v-model="regData.phoneNumber"
                          outlined
                          dense
                          label="Phone Number"
                          append-icon="mdi-phone"
                        ></v-text-field>
                </v-row>

                <v-row>
                        <v-text-field
                          v-model="regData.address"
                          outlined
                          dense
                          label="Address"
                          append-icon="mdi-map-marker"
                        ></v-text-field>
                </v-row>

                <v-row>
                        <v-select
                          :items="country"
                          label="Select Country"
                          v-model="regData.country"
                          append-icon="mdi-flag"
                          outlined
                          dense
                          item-text="name"
                          @change="getCountryStates"
                        ></v-select>
                </v-row>

                <v-row>
                        <v-select
                          :items="states"
                          label="Select State"
                          append-icon="mdi-map-marker"
                          outlined
                          dense
                          item-text="name"
                          v-model="regData.state"
                        ></v-select>
                </v-row>


               

                <v-layout color="#BD2121">
                  <h5 class="text-danger" align="center">
                    {{ errorMessage }}
                  </h5>
                </v-layout>

                <v-layout>
                  <v-btn
                    align="center"
                    class="buttonStyle"
                    @click.native="submitReg"
                    block
                  >
                    Sign up
                  </v-btn>
                </v-layout>

              </v-col>
            </v-row>

             <!--- Success Modal ---->
            <v-dialog v-model="modalSuccess" persistent max-width="350">
                          <v-card
                            style="border-radius:15px;height:auto; padding:10px;"
                          >
                            <v-card-text>
                              <v-row wrap>
                                <v-col cols="12" align="center" justify="center">
                                  <v-icon style="color:#456928; font-size:42px;"
                                    >mdi-checkbox-marked-circle-outline</v-icon
                                  >
                                </v-col>
                              </v-row>
                              <v-row wrap>
                                <v-col cols="12" align="center" justify="center">
                                  <h4>Registration Successful</h4>
                                  <p>
                                    Your registration has been
                                    submitted and awaiting admin approval
                                  </p>
                                </v-col>
                              </v-row>

                              <v-row wrap>
                                <v-col cols="12" align="center" justify="center">
                                  <v-btn
                                    block
                                    dark
                                    color="#456928"
                                    @click="redirectLogin"
                                  >
                                    OK
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
            </v-dialog>

          </v-col>
        </v-row>
      </v-col>
      <v-col col="12" md="7" class="loginPadding hidden-sm-and-down ">
        <v-img
          contain
          src="/img/deliveryImage2.webp"
          style="margin: 200px 0px 0px 0px; padding: 0px; width: 100%;"
        >
        </v-img>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { Country, State } from "country-state-city";

export default {
  name: "Logistics",

  data: () => ({
    loader: false,
    checkbox: false,
    errorMessage: "",
    show3: false,
    modalSuccess:false,
    regData: {
      name: "",
      contactPerson: "",
      email: "",
      password: "",
      phoneNumber: "",
      address: "",
      country: "",
      state: "",
    },
    country: [],
    states: [],
    cities: [],
    selectedCountry: {},
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
   mounted() {
    const self = this;
    self.getCountry();
  },
  methods: {
    submitReg() {
      this.loader = true;
      this.errorMessage = "";

      this.$http
        .post(`${process.env.VUE_APP_URL}logistics`, this.regData)
        .then(() => {
          this.loader = false;
          this.modalSuccess = true;
        })
        .catch((error) => {
          this.loader = false;
          if (error.response.data.data.length >= 1) {
            this.errorMessage = error.response.data.data.toString();
          }
        });
    },
    getCountry() {
      this.country = Country.getAllCountries();
    },
    getCountryStates(evt) {
      this.country.forEach((element) => {
        if (element.name == evt) {
          this.states = State.getStatesOfCountry(element.isoCode);
        }
      });
    },
    redirectLogin() {
      this.$router.push("/logistics-login");
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.loginPadding {
  margin: 0px !important;
  padding: 0px !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

.titleStyle {
  width: 511px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
}

.titleStyleColor {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  text-align: center;
}

.centerImg2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  text-align: center;
}

.buttonStyle {
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
}
</style>
